<template>
  <div class="verify__content">
    <h3>You are now an active Mediascience Panel member.</h3>
    <p>
      For your records, your member id is <span>{{ verify.member_id }}</span>
    </p>
    <p>
      Our staff will contact you by email when you are eligible for our next
      study.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "VerifyContent",
  data() {
    return {
      memberId: ""
    };
  },
  computed: {
    ...mapGetters(["verify"])
  },
  mounted() {
    this.memberId = this.$route.query.c;
    this.init();
  },
  methods: {
    ...mapActions(["verifyParticipant"]),
    init() {
      if (this.memberId != null) {
        this.verifyParticipant(this.memberId);
      } else {
        console.log(" else memberId:", this.memberId);
      }
    }
  }
};
</script>

<style></style>
