<template>
  <div class="admin">
    hello from admin
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Admin",
  data() {
    return {
      particpantsRef: firebase.database().ref("participant"),
      years: []
    };
  },
  created() {
    this.getParticipant();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "createParticipant",
      "fetchEducations",
      "fetchEthnicities",
      "fetchIncomes",
      "fetchOccupations"
    ]),
    getParticipant() {
      let ref = this.particpantsRef;
      let years = this.years;
      return new Promise(function(resolve, reject) {
        ref.on("child_added", snap => {
          years[snap.key] = snap.val();
          resolve(years);
        });
      }).then(() => {
        let yearsMap = new Map(Object.entries(this.years));
        let yearsMapEntries = [...yearsMap.entries()];
        let yearsMapValues = [...yearsMap.values()];
        let participantArray = [];
        yearsMapValues.map(function(elem, index, array) {
          let element = elem;
          // console.log('elem:',elem[''])
          for (var key in element) {
            if (element.hasOwnProperty(key)) {
              let what = element[key];
              // console.log('what:',what)
              let whatjson = JSON.parse(JSON.stringify(what));
              participantArray.push(whatjson);
            }
          }
        });
        let longDOB = [];
        // console.log('participantArray:',participantArray)
        participantArray.forEach(function(element, index, array) {
          // console.log('element:',element)
          // console.log('index:',index)

          let dob_year = element["dob_year"];
          if (dob_year != undefined) {
            if (dob_year.length > 4 || dob_year.length > 4) {
              console.log("dob_year.length:", dob_year.length);
              dob_year = dob_year.substring(0, 5);
              // array[index]['dob_year'] = dob_year.replace(/ /g,'')
              longDOB.push(array[index]);
              console.log("dob_year greater than 4:", {
                year: dob_year,
                element: array[index]
              });
            }
          } else {
            // console.log('array[index]:',array[index])
          }
        });
        this.updateParticipant(longDOB);
      });
    },
    updateParticipant(partipant) {
      let data = partipant;
      console.log("partipant in updateParticipant:", partipant);
      data.forEach(element => {
        console.log("element in updatePart:", element);
        // let unJson = JSON.parse(element)
        this.sendParticipant(element);
      });
    },
    sendParticipant(element) {
      let data = element;
      console.log("data:", data);
      // this.createParticipant(data)
      //     .then((response) => {
      //       console.log('response:',response)
      //     })
    }
  }
};
</script>

<style></style>
