import Vue from "vue";

const state = {
  participant: [],
  verify: [],
  survey: [],
  error: []
};

const getters = {
  participant: state => state.participant,
  verify: state => state.verify,
  survey: state => state.survey,
  error: state => state.error
};

const actions = {
  createParticipant({ commit, rootState }, participant) {
    // console.log('participant in createParticipant store:', participant);
    return new Promise((resolve, reject) => {
      Vue.http
        .post(
          rootState.apiRegURL + "?api_key=" + rootState.apiRegKey,
          participant
        )
        .then(response => {
          const j = response.data;
          const status = j.status;
          // console.log('j:', j);
          // console.log('status:', status);
          if (status === "OK") {
            setTimeout(() => {
              let data = j;
              commit("CREATE_PARTICIPANT", data);
              resolve(data);
            }, 1000);
          } else {
            commit("SET_ERRORS", j);
            reject(j);
          }
        })
        .catch(error => {
          commit("SET_ERRORS", error);
          reject(error);
        });
    });
  },
  verifyParticipant({ commit, rootState }, memberHash) {
    Vue.http
      .get(
        rootState.apiChiURL + memberHash + "?api_key=" + rootState.apiSurveyKey
      )
      .then(response => {
        const j = response.data;
        // console.log("verify j:",j)
        if (j.status === "OK") {
          let response = j.data;
          // console.log("verify response:",response)
          commit("VERIFY_PARTICIPANT", response);
        } else {
          state.error = j.data;
          console.log(" verify state.error:", state.error);
        }
      });
  },
  surveyLinks({ commit, rootState }, surveylinks) {
    Vue.http
      .get(rootState.apiSurveyURL + "?api_key=" + rootState.apiSurveyKey)
      .then(response => {
        const j = response.data;
        // console.log("survey j:",j)
        if (j.status === "OK") {
          let response = j.data;
          // console.log("survey response:",response)
          commit("SURVEY_LINKS", response);
        } else {
          state.error = j.data;
          console.log("survey state.error:", state.error);
        }
      });
  }
};

const mutations = {
  CREATE_PARTICIPANT(state, response) {
    state.participant = response;
  },
  VERIFY_PARTICIPANT(state, response) {
    state.verify = response;
  },
  SURVEY_LINKS(state, response) {
    state.survey = response;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
