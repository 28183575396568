import Vue from "vue";
import Router from "vue-router";
import { routes } from "./routes";
import firebase from "firebase";
import { scroll } from "../utils/directives";
import ScrollReveal from "scrollreveal";
import VueMask from "v-mask";
import VeeValidate from "vee-validate";
import VueResource from "vue-resource";
import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue";
import { getPath, vuex_mixin } from "../utils/mixins";

Vue.use(VueMask);
Vue.use(Router);
Vue.use(VeeValidate, { events: "change" });
Vue.use(VueResource);

// directives
Vue.directive("scroll", scroll);

Vue.mixin(getPath);
Vue.mixin(vuex_mixin);

// raven
// Raven.config(
//   "https://e4c9c8348fd24e10b0eeb48d7dab378e@sentry.mediasciencelabs.com/8"
// )
//   .addPlugin(RavenVue, Vue)
//   .install();

//scrollReveal
window.sr = ScrollReveal({ reset: false });

// firebase
var config = {
  apiKey: "AIzaSyA0ZvktEJc_uQE2iIIjBMQx5BRTodzypH4",
  authDomain: "web-tracker-d66ac.firebaseapp.com",
  databaseURL: "https://web-tracker-d66ac.firebaseio.com",
  projectId: "web-tracker-d66ac",
  storageBucket: "web-tracker-d66ac.appspot.com",
  messagingSenderId: "178239189523"
};
firebase.initializeApp(config);
window.firebase = firebase;

var router = new Router({
  base: "/",
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes
});

export { router };
