<template>
  <div
    id="loading-spinner"
    class="loading-spinner js-loading-spinner"
    role="alert"
    aria-live="assertive"
  >
    <p class="vh js-loading-spinner-copy">Content is loading...</p>
  </div>
</template>

<script>
export default {
  name: "Spinner"
};
</script>

<style></style>
