<template>
  <div class="main__view">
    <nav-bar></nav-bar>
    <!-- <transition name="router-slide"> -->
    <router-view></router-view>
    <!-- </transition> -->
    <landing-signup></landing-signup>
    <the-footer v-if="pageTitle !== 'youth-panel'"></the-footer>
    <youth-footer v-else></youth-footer>
  </div>
</template>

<script>
import NavBar from "../components/Navigation.vue";
import TheFooter from "../components/Footer.vue";
import LandingSignup from "../pages/Landing/LandingSignup.vue";
import YouthFooter from "./YouthPanel/YouthPanelFooter.vue";
export default {
  name: "MainView",
  components: { NavBar, TheFooter, LandingSignup, YouthFooter },
  data() {
    return {};
  },
  computed: {
    pageTitle() {
      let path = this.$route.path.replace(/\//g, "");
      function whichPath(path) {
        let options = {
          "confirm-email": "verify",
          "survey-links": "study surveys",
          site: "select your city",
          default: path
        };
        return options[path] || options["default"];
      }
      return whichPath(path);
    }
  }
};
</script>
