<template>
  <div class="subpage">
    <div class="subpage__content maxW_1024 border2">
      <div
        class="subpage__content--title border3"
        v-if="pageTitle === 'studies'"
      >
        <h2>Current and Upcoming Studies</h2>
      </div>
      <div class="subpage__content--title border3" v-else>
        <h2>{{ pageTitle }}</h2>
      </div>
      <div class="subpage__content--body border3">
        <!-- <transition name="router-slide"> -->
        <about-content v-if="pageTitle === 'about'" />
        <studies-content v-if="pageTitle === 'studies'" />
        <contact-content v-if="pageTitle === 'contact'" />
        <location-content v-if="pageTitle === 'location'" />
        <privacy-content v-if="pageTitle === 'privacy'" />
        <verify-content v-if="pageTitle === 'verify'" />
        <survey-content v-if="pageTitle === 'study surveys'" />
        <redirect-content v-if="pageTitle === 'select your city'" />
        <youth-panel v-if="pageTitle === 'youth-panel'" />
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
import AboutContent from "./AboutContent.vue";
import StudiesContent from "./StudiesContent.vue";
import ContactContent from "./ContactContent.vue";
import LocationContent from "./LocationContent.vue";
import PrivacyContent from "./PrivacyContent.vue";
import VerifyContent from "./VerifyContent.vue";
import SurveyContent from "./StudyLinksContent.vue";
import RedirectContent from "./SiteRedirect.vue";
import YouthPanel from "../YouthPanel/YouthPanelLanding.vue";
export default {
  name: "Subpage",
  components: {
    AboutContent,
    StudiesContent,
    ContactContent,
    PrivacyContent,
    LocationContent,
    VerifyContent,
    SurveyContent,
    RedirectContent,
    YouthPanel
  },
  data() {
    return {
      // path: ' '
    };
  },

  computed: {
    pageTitle() {
      let path = this.$route.path.replace(/\//g, "");
      function whichPath(path) {
        let options = {
          "confirm-email": "verify",
          "survey-links": "study surveys",
          site: "select your city",
          default: path
        };
        return options[path] || options["default"];
      }
      return whichPath(path);
    }
  }
};
</script>

<style></style>
