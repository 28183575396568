<template>
  <div class="landing">
    <div class="about__content">
      <p class="about__content--p">
        MediaScience Panel is a private media research lab in Austin and
        Chicago. Our studies cover audience and consumer trends in multiple
        platforms including mobile apps, video games, online publications,
        wearable technology like virtual reality headsets, trending TV shows,
        and television broadcast in real-time from sports to politics.
      </p>
      <p class="about__content--p">
        Our panel is comprised of local residents who participate in studies and
        have the opportunity to voice their opinion, along with being
        compensated for their participation time.
      </p>
      <div class="body__content maxW_1024 border">
        <landing-proof></landing-proof>
        <br /><br /><br />
        <landing-faq></landing-faq>
      </div>
    </div>
  </div>
</template>

<script>
import LandingProof from "../Landing/LandingProof.vue";
import LandingFaq from "../Landing/LandingFAQ.vue";
export default {
  name: "AboutContent",
  components: { LandingProof, LandingFaq },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".about__content--p",
        {
          origin: "bottom",
          distance: "200px",
          opacity: 0,
          delay: 100,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          mobile: false,
          reset: false
        },
        250
      );
    }
  }
};
</script>

<style></style>
