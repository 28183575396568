<template>
  <div class="site-redirect">
    <div class="proof__body--card" v-for="item in city" :key="item.id">
      <a :href="item.link" target="_blank">
        <h2>{{ item.name }}</h2>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteRedirect",
  data() {
    return {
      city: [
        {
          name: "Austin",
          link: "https://www.mediasciencepanel.com"
        },
        {
          name: "Chicago",
          link: "https://www.mediasciencepanel.com"
        }
      ]
    };
  }
};
</script>

<style></style>
