import Vue from "vue";
import axios from "axios";

const state = {
  youthParticipant: {},
  guardian: {},
  error: []
};

const getters = {
  youthParticipant: state => state.youthParticipant,
  guardian: state => state.guardian
};

const actions = {
  createYouthParticipant({ commit, rootState }, youthParticipant) {
    return new Promise((resolve, reject) => {
      axios
        .post(rootState.youthURL + rootState.apiKeyYouth, youthParticipant)
        .then(response => {
          const j = response;
          const status = j.data.status;
          if (status === "OK") {
            let data = j.data.data;
            commit("CREATE_YOUTH", data);
            resolve(status);
          } else {
            commit("SET_ERRORS", j);
            reject(j);
          }
        })
        .catch(error => {
          commit("SET_ERRORS", error);
          reject(error);
        });
    });
  },
  verifyGuardian({ commit, rootState }, guardian) {
    return new Promise((resolve, reject) => {
      axios
        .post(rootState.guardianURL + rootState.apiKeyYouth, guardian)
        .then(response => {
          const j = response;
          // console.log("response:", j);
          const status = j.data.status;
          if (status === "OK") {
            let data = j.data.data;
            // console.log("data:", data);
            commit("VERIFY_GUARDIAN", data);
            resolve(status);
          } else {
            // console.log("else:", j);
            commit("SET_ERRORS", j);
            reject(j);
          }
        })
        .catch(error => {
          commit("SET_ERRORS", error);
          reject(error);
        });
    });
  }
};
const mutations = {
  CREATE_YOUTH(state, response) {
    state.youthParticipant.response = response;
  },
  VERIFY_GUARDIAN(state, response) {
    // console.log("response VERIFY_GUARDIAN:", response);
    state.guardian.id = response;
    state.youthParticipant.guardian_id = response;
  },
  SET_ERRORS(state, response) {
    // console.log("response SET_ERRORS:", response);
    state.error = response.data.data;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
