import { mapGetters, mapActions } from "vuex";

export const getPath = {
  computed: {
    getPath() {
      // console.log("this.$route:", this.$route);
      return this.$route.path.replace(/\//g, "");
    }
  }
};

export const vuex_mixin = {
  computed: {
    ...mapGetters([
      "youthParticipant",
      "guardian",
      "ethnicities",
      "youth_educations"
    ])
  },
  methods: {
    ...mapActions([
      "verifyGuardian",
      "createYouthParticipant",
      "fetchEthnicities",
      "fetchYouthEducations"
    ])
  }
};
