<template>
  <div class="hero">
    <div class="hero__content maxW_1024">
      <div class="hero-text">
        <p class="banner hero-reveal">
          Join the MediaScience Panel today!<br />
          Get paid to voice your opinion
        </p>
        <br /><br />
        <!-- <h2 class="CTA hero-reveal">Get paid to voice your opinion</h2> -->
        <!-- <video class="hero-reveal" :src="video" type="video/mp4" :poster="poster" autoplay controls/> -->
        <video
          class="hero-reveal"
          width="100%"
          height="100%"
          :src="video"
          type="video/mp4"
          :poster="poster"
          autoplay
          controls
        />
        <li class="info hero-reveal">
          Participate in
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/studies"
            style="text-decoration: underline"
          >
            media research studies and focus groups
          </router-link>
          at one of our state-of-the art facilities located in Austin, TX, or
          Chicago, IL.
        </li>
        <li class="info hero-reveal" style="color: #FFB94A">
          If you are under the age of 18, please register with our
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/youth-panel"
            style="text-decoration: underline"
          >
            Youth Panel
          </router-link>
        </li>
        <li class="info hero-reveal">
          Panel members earn between $30-40/hour for their participation.
        </li>
        <li class="info hero-reveal">
          You MUST be a US resident in order to participate.
        </li>
      </div>
      <hero-form></hero-form>
    </div>
  </div>
</template>

<script>
import HeroForm from "./LandingHeroForm.vue";
export default {
  data() {
    return {
      video: "./MediaScience.mp4",
      poster: "./MediaSciencePoster.png"
    };
  },
  name: "LandingHero",
  components: { HeroForm },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".hero-reveal",
        {
          origin: "left",
          distance: "200px",
          opacity: 0,
          scale: 0.8,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000
        },
        80
      );
    }
  }
};
</script>

<style></style>
