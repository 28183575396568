<template>
  <div class="landing">
    <!-- background div -->
    <div class="skewed__square"></div>
    <landing-hero></landing-hero>
    <landing-body></landing-body>
  </div>
</template>

<script>
import LandingHero from "./LandingHero.vue";
import LandingBody from "./LandingBody.vue";
import { handleScroll, elementPosition } from "../../../utils/helpers.js";
export default {
  name: "Landing",
  components: { LandingHero, LandingBody }
};
</script>
