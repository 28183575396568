import Vue from "vue";

const state = {
  panel: {
    educations: [],
    youth_educations: [],
    ethnicities: [],
    incomes: [],
    occupations: [],
    labs: []
  }
};

const getters = {
  incomes: state => state.panel.incomes,
  occupations: state => state.panel.occupations,
  educations: state => state.panel.educations,
  youth_educations: state => state.panel.youth_educations,
  ethnicities: state => state.panel.ethnicities,
  labs: state => state.panel.labs
};

const actions = {
  fetchYouthEducations({ commit }) {
    let educations = [
      {
        id: 12,
        name: "First Grade"
      },
      {
        id: 13,
        name: "Second Grade"
      },
      {
        id: 14,
        name: "Third Grade"
      },
      {
        id: 15,
        name: "Fourth Grade"
      },
      {
        id: 16,
        name: "Fifth Grade"
      },
      {
        id: 17,
        name: "Sixth Grade"
      },
      {
        id: 18,
        name: "Seventh Grade"
      },
      {
        id: 19,
        name: "Eighth Grade"
      },
      {
        id: 20,
        name: "Ninth Grade"
      },
      {
        id: 21,
        name: "Tenth Grade"
      },
      {
        id: 22,
        name: "Eleventh Grade"
      },
      {
        id: 23,
        name: "Twelfth Grade"
      }
    ];
    commit("FETCH_YOUTH_EDUCATIONS", educations);
    return;
  },

  fetchEducations({ commit }) {
    let educations = [
      {
        id: 10,
        nyid: "1",
        name: "Grammar school"
      },
      {
        id: 1,
        nyid: "2",
        name: "High school or equivalent"
      },
      {
        id: 2,
        nyid: "3",
        name: "Vocational/technical school (2 year)"
      },
      {
        id: 3,
        nyid: "4",
        name: "Some college"
      },
      {
        id: 4,
        nyid: "5",
        name: "Bachelor's degree"
      },
      {
        id: 5,
        nyid: "6",
        name: "Master's degree"
      },
      {
        id: 6,
        nyid: "7",
        name: "Doctoral degree"
      },
      {
        id: 7,
        nyid: "8",
        name: "Professional degree (MD, JD, etc.)"
      },
      {
        id: 8,
        nyid: "9",
        name: "Other qualification"
      },
      {
        id: 9,
        nyid: "10",
        name: "Prefer not to answer"
      }
    ];
    commit("FETCH_EDUCATIONS", educations);
    return;
  },
  fetchEthnicities({ commit }) {
    let ethnicities = [
      {
        id: 1,
        name: "White/Caucasian"
      },
      {
        id: 2,
        nyid: "3",
        name: "African American"
      },
      {
        id: 3,
        nyid: 2,
        name: "Hispanic"
      },
      {
        id: 4,
        name: "Asian"
      },
      {
        id: 5,
        name: "Native American"
      },
      {
        id: 6,
        name: "Pacific Islander"
      },
      {
        id: 7,
        name: "Other"
      },
      {
        id: 8,
        name: "Prefer not to answer "
      }
    ];
    commit("FETCH_ETHNICITIES", ethnicities);
    return;
  },
  fetchIncomes({ commit }) {
    let incomes = [
      {
        id: 1,
        name: "No Income - $0/year"
      },
      {
        id: 2,
        name: "Under $20k/year"
      },
      {
        id: 3,
        name: "Up to $45k/year"
      },
      {
        id: 4,
        name: "Up to $70k/year"
      },
      {
        id: 5,
        name: "Up to $95k/year"
      },
      {
        id: 6,
        name: "Up to $120k/year"
      },
      {
        id: 7,
        name: "Up to $145k/year"
      },
      {
        id: 9,
        nyid: 8,
        name: "Over $145k/year"
      },
      {
        id: 8,
        nyid: 9,
        name: "Prefer not to answer"
      }
    ];
    commit("FETCH_INCOMES", incomes);
    return;
  },
  fetchOccupations({ commit }) {
    let occupation = [
      {
        id: 1,
        name: "Manager or administrator"
      },
      {
        id: 2,
        name: "Professional (Doctor, nurse, lawyer, secretary, etc...)"
      },
      {
        id: 3,
        name: "Tradesperson (Electrician, plumber, etc...)"
      },
      {
        id: 4,
        name: "Retail (Cashier, stocker, etc...)"
      },
      {
        id: 5,
        name:
          "Industrial Laborer (Machine operator, construction worker, etc...)"
      },
      {
        id: 6,
        name:
          "Technical (Computer technician, graphic designer, programmer, etc...)"
      },
      {
        id: 7,
        name: "Government"
      },
      {
        id: 8,
        name: "Education / Teaching"
      },
      {
        id: 9,
        name: "Stay at home parent / Household duties"
      },
      {
        id: 10,
        name: "Student"
      },
      {
        id: 11,
        name: "Retired"
      },
      {
        id: 12,
        name: "Not currently employed"
      },
      {
        id: 13,
        name: "Other occupation"
      },
      {
        id: 14,
        name: "Prefer not to answer"
      }
    ];
    commit("FETCH_OCCUPATIONS", occupation);
    return;
  },
  fetchLabs({ commit, rootState }) {
    Vue.http
      .get(rootState.apiURL + "labs" + "?api_key=" + rootState.demKey)
      .then(response => {
        const j = response.data;
        if (j.status === "OK") {
          let response = j.data;
          commit("FETCH_LABS", response);
        } else {
          state.error = j.data;
        }
      });
  },
  validateCode({ rootState }, code) {
    let url = `${rootState.apiURL}signups/${code}/validate?api_key=${rootState.demKey}`;
    return Vue.http.get(url).then(
      response => {
        //debugger
        const j = response.data;
        if (j.status === "OK") {
          if (j.data) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      error => {
        console.error(error);
        return true;
      });
  }
};

const mutations = {
  FETCH_YOUTH_EDUCATIONS(state, response) {
    // console.log(response);
    state.panel.youth_educations = response;
  },
  FETCH_EDUCATIONS(state, response) {
    state.panel.educations = response;
  },
  FETCH_ETHNICITIES(state, response) {
    state.panel.ethnicities = response;
  },
  FETCH_INCOMES(state, response) {
    state.panel.incomes = response;
  },
  FETCH_OCCUPATIONS(state, response) {
    state.panel.occupations = response;
  },
  FETCH_LABS(state, response) {
    state.panel.labs = response;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
