<template>
  <div class="landing">
    <div class="about__content">
      <p
        class="about__content--p"
        style="font-style: italic; font-weight: bold; font-size: 1em"
      >
        *DISCLAIMER: Once you’ve registered, you will receive email invitations
        for each study you may qualify for. Do NOT call the office to request
        placement into a study.
      </p>
    </div>

    <div class="about__content" v-for="study in studies" :key="study.name">
      <p class="about__content--p">
        <span style="color: #FFB94A">{{ study.name }}: </span>
        <span>{{ study.description }}</span>
        <li v-for="e in study.extras" :key="e" :value="e">
          {{ e }}
        </li>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StudiesContent",
  data() {
    return {
      studies: [
        {
          name: "S350",
          description:
            "(For ages 18-49) Researchers are interested in how sports fans evaluate game coverage."
        },
        {
          name: "G9",
          description:
            "(Ages 18-64)  We are interested in how consumers consume different types of media."
        }
      ]
    };
  },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".about__content",
        {
          origin: "top",
          distance: "80px",
          opacity: 0,
          scale: 0.2,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          delay: 250
        },
        500
      );
    }
  }
};
</script>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
