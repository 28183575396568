<template>
  <!-- <div class="navigation" :class="opaqueBG" style="background-color: black"> -->
  <div class="navigation" style="background-color: black">
    <div class="navigation__content maxW_1024">
      <div @mouseenter="logoActive = true" @mouseleave="logoActive = false">
        <router-link to="/">
          <!-- <ms-logo-white class="logo-reveal" v-if="whichLogo" key="1"></ms-logo-white> -->
          <!-- <ms-logo class="logo-reveal" v-else key="1"></ms-logo> -->
          <ms-logo class="logo-reveal" v-if="logoActive" key="1" />
          <ms-logo-white class="logo-reveal" v-else key="1" />
        </router-link>
      </div>
      <ul style="padding-top: 11px">
        <li class="navSection primary">
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/"
            exact
            ><p>Home</p></router-link
          >
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/about"
            ><p>About</p></router-link
          >
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/studies"
            ><p>Studies</p></router-link
          >
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/location"
            ><p>Location</p></router-link
          >
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/contact"
            ><p>Contact</p></router-link
          >
          <router-link
            class="nav__link"
            active-class="nav__link--active"
            to="/youth-panel"
            ><p>Youth Panel</p></router-link
          >
        </li>
        <!-- <li class="navSection secondary">
          <a
            class="nav__link"
            active-class="nav__link--active"
            href="https://profile.themediapanel.com/"
            target="_blank"
            ><p>Redeem Points</p></a
          >
        </li> -->
        <li class="navSection mobile">
          <div id="hamburger" @click="toggleMobileNav">
            <a>
              <span style="background: #fcfdff"></span>
              <span style="background: #fcfdff"></span>
              <span style="background: #fcfdff"></span>
            </a>
          </div>
          <div class="popup__mobile" v-show="popupMobile">
            <div
              class="popup__mobile--container"
              :class="{ popupActive: popupMobile }"
            >
              <div class="popup__mobile--content">
                <div class="popup__close" @click="toggleMobileNav">
                  <span></span>
                  <span></span>
                </div>
                <div class="popup__mobile--navlinks">
                  <ul>
                    <li class="navSection mobile-links">
                      <router-link class="nav__link--mobile" to="/"
                        ><p>Home</p></router-link
                      >
                      <router-link class="nav__link--mobile" to="/about"
                        ><p>About</p></router-link
                      >
                      <router-link class="nav__link--mobile" to="/studies"
                        ><p>Studies</p></router-link
                      >
                      <router-link class="nav__link--mobile" to="/location"
                        ><p>Location</p></router-link
                      >
                      <router-link class="nav__link--mobile" to="/contact"
                        ><p>Contact</p></router-link
                      >
                      <router-link class="nav__link--mobile" to="/youth-panel"
                        ><p>Youth Panel</p></router-link
                      >
                    </li>
                    <!-- <li class="navSection mobile-links-secondary">
                      <a
                        class="nav__link--mobile"
                        href="https://profile.themediapanel.com/"
                        target="_blank"
                        ><p>Redeem Points</p></a
                      >
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MsLogoWhite from "./LogoWhite.vue";
import MsLogo from "./Logo.vue";
import { handleScroll } from "../../utils/handleScroll.js";
import { getPath } from "../../utils/mixins.js";
export default {
  name: "Navigation",
  components: { MsLogoWhite, MsLogo },
  mixin: [getPath],
  data() {
    return {
      popupMobile: false,
      scrollTop: 0,
      delta: 30,
      logoActive: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.reveal();
  },
  computed: {
    opaqueBG() {
      let path = this.$route.path.replace(/\//g, "");
      if (path != "") return ["nav-scroll"];
      if (this.scrollTop > this.delta) {
        this.panelLg = false;
        return ["nav-scroll"];
      } else {
        return;
      }
    },
    whichLogo() {
      let path = this.$route.path;
      path = path.replace(/\//g, "");
      if (path != "") {
        return false;
      } else {
        if (this.scrollTop < this.delta) return true;
      }
    }
  },
  methods: {
    reveal() {
      sr.reveal(
        ".nav__link",
        {
          origin: "top",
          distance: "200px",
          opacity: 0,
          delay: 100,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          mobile: false
        },
        50
      );
      sr.reveal(
        ".logo-reveal",
        {
          origin: "top",
          distance: "200px",
          opacity: 0,
          delay: 0,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000
        },
        50
      );
    },
    toggleMobileNav() {
      console.log("hello from toggleMobileNav");
      !this.popupMobile
        ? (this.popupMobile = true)
        : (this.popupMobile = false);
    },
    handleScroll(e) {
      // Calculate how far we've scrolled
      this.scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
    }
  }
};
</script>
