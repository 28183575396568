<template>
  <div class="hero-form">
    <div class="landing__form">
      <div class="landing__form--header">
        <h2 class="header__title">{{ headerTitle }}</h2>
        <p class="header__subtitle">{{ headerSubtitle }}</p>
      </div>
      <div
        id="progressbar"
        class="header__progress progress-bar progress-bar-striped progress-bar-animated landingform__multistep"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
        :class="progressBg"
      ></div>
      <div class="landing__form--body">
        <form class="signup__form" @keydown.enter.prevent>
          <div class="signup__form--first initial" v-if="step === 1" key="1">
            <!--////////////// First Name  /////////////////////-->
            <div class="form-group has-feedback">
              <input
                v-validate="'required|alpha_dash|min:2'"
                data-vv-as="First Name"
                type="text"
                v-model="participant.first_name"
                class="form-control"
                id="firstname"
                data-vv-name
                placeholder="Your First Name"
                name="firstname"
                required
              />
              <span v-show="errors.has('firstname')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("firstname") }}
              </span>
            </div>
            <!--////////////// Last Name  /////////////////////-->
            <div class="form-group has-feedback">
              <input
                v-validate="'required|alpha_dash|min:2'"
                data-vv-as="Last Name"
                v-model="participant.last_name"
                type="text"
                class="form-control"
                id="lastname"
                placeholder="Your Last Name"
                name="lastname"
                required
              />
              <span v-show="errors.has('lastname')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("lastname") }}
              </span>
            </div>
            <!--////////////// Email  /////////////////////-->
            <div class="form-group has-feedback">
              <input
                v-validate="'required|email'"
                type="text"
                name="email"
                v-model="participant.email"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('email') }"
                id="email"
                placeholder="Your Email"
                required
              />
              <span v-show="errors.has('email')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("email") }}
              </span>
            </div>
            <!--////////////// Phone  /////////////////////-->
            <div class="form-group has-feedback">
              <input
                v-validate="'required|numeric|min:10|max:10'"
                type="tel"
                name="phone"
                v-model="participant.phone"
                class="form-control"
                id="phone"
                placeholder="Your Phone Number"
                required
              />
              <span v-show="errors.has('phone')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("phone") }}
              </span>
            </div>
          </div>
          <div class="signup__form--second secondary" v-if="step === 2" key="2">
            <!--////////////// Gender  /////////////////////-->
            <div class="form-group has-feedback gender-radio">
              <label class="gender gender-radio-label">I am</label>
              <div class="button_radio">
                <input
                  type="radio"
                  name="gender"
                  id="genderbr"
                  v-validate="'required|included:M,F,O'"
                  value="M"
                  v-model="participant.gender"
                />
                <label for="genderbr"
                  >Male
                  <span></span>
                </label>
                <input
                  type="radio"
                  name="gender"
                  id="genderbr1"
                  value="F"
                  v-model="participant.gender"
                />
                <label for="genderbr1"
                  >Female
                  <span></span>
                </label>
                <input
                  type="radio"
                  name="gender"
                  id="genderbr2"
                  value="O"
                  v-model="participant.gender"
                />
                <label for="genderbr2"
                  >Other
                  <span></span>
                </label>
              </div>
              <span v-show="errors.has('gender')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("gender") }}
              </span>
            </div>
            <!--////////////// DOB  /////////////////////-->
            <div class="form-group has-feedback dob-radio">
              <label class="dobInput-label">Birthday</label>
              <input
                type="hidden"
                v-model="hiddenDOB"
                v-validate="'required'"
                name="Birthday"
              />
              <datepicker
                class="datepicker"
                :format="dateFormatter"
                :disabledDates="state.disabledDates"
                :initialView="'year'"
                name="Birthday"
              ></datepicker>
              <span v-show="errors.has('Birthday')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("Birthday") }}
              </span>
            </div>
            <!--////////////// Location  /////////////////////-->
            <div class="form-group has-feedback location-radio">
              <label class="location-radio-label">Location</label>
              <div class="button_radio">
                <select
                  name="location"
                  v-validate="'required|included:1,2,4,5,6'"
                  v-model="participant.id_address"
                  class="form-control"
                  required
                >
                  <option value="1">Austin</option>
                  <option value="2">Chicago</option>
                  <option value="4">New York</option>
                  <option value="5">Online Only</option>
                  <option value="6">Canada</option>
                </select>
              </div>
              <span v-show="errors.has('location')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("location") }}
              </span>
            </div>
            <!--////////////// Zipcode /////////////////////-->
            <div class="form-group has-feedback zipcode-radio">
              <label class="zipcode-label">Zip Code</label>
              <div class="button_radio">
                <input
                  name="zipcode"
                  v-validate="'required|alpha_num|min:5|max:6'"
                  v-model="participant.zip_code"
                  class="zipcodeInput form-control"
                  type="text"
                  placeholder="(Zip Code)"
                />
              </div>
              <span v-show="errors.has('zipcode')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("zipcode") }}
              </span>
            </div>
            <!--////////////// State /////////////////////-->
            <!--
            <div v-show="participant.id_address !== 6" class="form-group">
              <label class="state-label">State</label>
              <select name="state_province" id="state_province" v-model="state_province">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                <option value="DC">District of Columbia</option>
              </select>
            </div>
            -->
            <!--////////////// How'd you hear about us  /////////////////////-->
            <div class="form-group has-feedback feedback-dropdown">
              <label class="feedback-label" for="referral"
                >How did you hear about us?</label
              >
              <select
                id="signupcodes"
                name="signupcodes"
                v-model="participant.notes"
                @change="otherFn"
                class="form-control"
                required
              >
                <option value="CTA">CTA Ad</option>
                <option value="SearchEngine"
                  >Search Engine (Google, Bing, etc.)</option
                >
                <option value="Ad">Advertisement</option>
                <option value="Article">Article / Blog</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="OtherSM">Other Social Media</option>
                <option value="WordOfMouth">Word of Mouth</option>
                <option value="Craigslist">Craigslist</option>
                <option value="DirectMail">Direct Mail</option>
                <option value="Radio">Radio</option>
                <option value="University">University</option>
                <option value="Friend">Friend</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="form-group" v-show="ctaClicked">
              <label class="feedback-label" for="cta"
                >Which Transit Line?</label
              >
              <select
                id="ctaVal"
                name="cta"
                v-model="transitLine"
                @change="ctaValue"
                class="form-control"
              >
                <option value="red_line">Red Line</option>
                <option value="blue_line">Blue Line</option>
              </select>
            </div>
            <div class="form-group" v-show="adClicked">
              <label class="feedback-label" for="ad"
                >Which Ad did you see?</label
              >
              <select
                id="adVal"
                name="ad"
                v-model="transitAd"
                @change="adValue"
                class="form-control"
              >
                <option value="sm_ad">Social Media Ad</option>
                <option value="tv_ad">TV Ad</option>
              </select>
            </div>
            <div class="form-group" v-show="participant.notes === 'Other'">
              <label class="feedback-label" for="other">Other</label>
              <input
                class="otherInput form-control"
                type="text"
                name="otherInput"
                id="val"
                placeholder="Add Other Option"
                @change="otherValue"
              />
            </div>
            <div class="form-group" v-show="participant.notes === 'Friend'">
              <label class="feedback-label" for="friend">Referred By</label>
              <input
                class="otherInput form-control"
                type="text"
                name="otherInput"
                id="friendVal"
                placeholder="Enter friend's email"
                @change="friendValue"
              />
            </div>
            <div class="form-group">
              <label
                class="feedback-label"
                for="has_signup_code"
                id="hassignupcodelabel"
                >Do You Have A Signup Code?</label
              >
              <div class="button_radio">
                <input
                  type="radio"
                  name="hassignupcode"
                  id="hscyes"
                  v-bind:value="true"
                  v-model="signupCode"
                />
                <label for="hscyes"
                  >Yes
                  <span></span>
                </label>
                <input
                  type="radio"
                  name="hassignupcode"
                  id="hscno"
                  v-bind:value="false"
                  v-model="signupCode"
                />
                <label for="hscno"
                  >No
                  <span></span>
                </label>
              </div>
            </div>
            <div class="form-group" v-if="signupCode">
              <label
                class="feedback-label"
                for="signup_code"
                id="signupCodeLabel"
                >Signup Code</label
              >
              <input
                class="otherInput form-control"
                type="text"
                name="signup_code"
                id="signup_code"
                placeholder="Enter Signup Code"
                @change="checkSignUpCode()"
              />
              <span v-show="invalidSignupCode" class="validate is-danger">
                <i class="fa fa-warning"></i>
                Invalid signup code - retry
              </span>
            </div>
          </div>
          <div
            class="signup__form--third demographics"
            v-if="step === 3"
            key="3"
          >
            <div class="form-group has-feedback">
              <label for="referral">Ethnicity</label>
              <select
                id="ethnicity"
                name="ethnicity"
                v-model="participant.id_ethnicity"
                class="form-control"
                v-validate="'required|included:1,2,3,4,5,6,7,8'"
                required
              >
                <option
                  v-for="ethnicity in ethnicities"
                  :key="ethnicity.id"
                  :value="ethnicity.id"
                  >{{ ethnicity.name }}</option
                >
              </select>
              <span v-show="errors.has('ethnicity')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("ethnicity") }}
              </span>
            </div>
            <div class="form-group has-feedback">
              <label for="referral">Education</label>
              <select
                id="education"
                name="education"
                v-model="participant.id_education"
                class="form-control"
                v-validate="'required|included:1,2,3,4,5,6,7,8,9,10'"
                required
              >
                <option
                  v-for="education in educations"
                  :key="education.id"
                  :value="education.id"
                  >{{ education.name }}</option
                >
              </select>
              <span v-show="errors.has('education')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("education") }}
              </span>
            </div>
            <div class="form-group has-feedback">
              <label for="referral">Income</label>
              <select
                id="income"
                name="income"
                v-model="participant.id_income"
                class="form-control"
                v-validate="'required|included:1,2,3,4,5,6,7,8,9'"
                required
              >
                <option
                  v-for="income in incomes"
                  :key="income.id"
                  :value="income.id"
                  >{{ income.name }}</option
                >
              </select>
              <span v-show="errors.has('income')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("income") }}
              </span>
            </div>
            <div class="form-group has-feedback">
              <label for="referral">Occupation</label>
              <select
                id="occupation"
                name="occupation"
                v-model="participant.id_occupation"
                class="form-control"
                v-validate="
                  'required|included:1,2,3,4,5,6,7,8,9,10,11,12,13,14'
                "
                required
              >
                <option
                  v-for="occupation in occupations"
                  :key="occupation.id"
                  :value="occupation.id"
                  >{{ occupation.name }}</option
                >
              </select>
              <span
                v-show="errors.has('occupation')"
                class="validate is-danger"
              >
                <i class=" fa fa-warning"></i>
                {{ errors.first("occupation") }}
              </span>
            </div>
          </div>
          <div class="signup__form--fourth result" v-if="step === 4" key="4">
            <!-- ///// refactor ///// -->
            <div class="success-errors-div">
              <div class="show-errors-div" v-show="isLoading">
                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </div>
              <div class="show-errors-div error" v-show="participantError">
                <i
                  class="fa fa-3x fa-exclamation-circle success-errors-icon"
                  aria-hidden="true"
                ></i>
                {{ error.data }}
              </div>
              <div class="show-errors-div success" v-show="participantSuccess">
                <i
                  class="fa fa-3x fa-check-circle-o success-errors-icon"
                  aria-hidden="true"
                ></i>
                <p>Success!</p>
              </div>
            </div>
            <p class="result__p p--1">
              You will receive a verification email to the address you provided.
            </p>
            <p class="result__p p--2">
              If you don’t see this in the next 24 hours, please check your spam
              folder or email us at:
            </p>
            <p class="result__p p--3">{{ panelAddress }}</p>

            <!-- ///// refactor ///// -->
          </div>
          <div class="signup__form--button" v-show="atResult">
            <button
              type="button"
              class="ms__button"
              v-show="atResult"
              @click.prevent="
                checkStep(step);
                track(step);
              "
            >
              <!-- <button class="ms__button" @click.prevent="checkStep(step);"> -->
              <p class="ms__button-tag">{{ btnTxt }}</p>
            </button>
            <button
              class="ms__button ms__button--invert"
              @click.prevent="step--"
              v-show="this.step != 1"
            >
              <span>previous</span>
            </button>
          </div>

          <div v-if="step == 1">
            <br />
            <p style="font-size: 10pt; text-align: right">
              By providing your email and phone number, you are consenting to be contacted via email, phone, and text for our research studies.
            </p>
          </div>

          <div class="signup__form--consent" v-if="step === 3">
            <p>
              1. By registering to become a panel member, you consent to receive
              study invitations through text messages, emails, and phone calls
              sent from MediaScience. 2. You may opt-out from receiving text
              messages at any time. 3. Consent to these terms is not a condition
              of participation. Message and Data Rates May Apply
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import FbEvents from "../../../utils/fb_events.js";
import { isNil, isEmpty, filterDOB, splitDOB } from "../../../utils/helpers.js";
import uuidV4 from "uuid/v4";
import Datepicker from "vuejs-datepicker";
import { handleScroll } from "../../../utils/handleScroll.js";
export default {
  name: "LandingForm",
  components: { Datepicker },
  data() {
    return {
      title: "Start Now!",
      subtitle: "Takes less than 30 seconds to join.",
      participant: {},
      step: 1,
      atResult: true,
      hiddenDOB: "",
      tag: "Next",
      showEx: false,
      isLoading: false,
      participantError: false,
      participantSuccess: false,
      ctaClicked: false,
      adClicked: false,
      transitAd: "",
      transitLine: "",
      signupCode: false,
      signupsRef: firebase.database().ref("signups"),
      particpantsRef: firebase.database().ref("participant"),
      referred_by: "",
      signupcode: "",
      invalidSignupCode: false,
      state: {
        disabledDates: {
          from: this.disabledDates()
        }
      }
    };
  },

  mounted() {
    this.init();
  },
  filters: {
    capitalize(value) {
      console.log("value:", value);
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  computed: {
    isValid() {
      console.log("hello from isValid");
      return (
        this.participant.first_name &&
        this.participant.last_name &&
        this.participant.email
      );
    },
    progressBg() {
      return this.step === 4 ? "progress-success" : "progress-warning";
    },
    headerTitle() {
      return {
        "1": "Start Now!",
        "2": "Start Now!",
        "3": "Before you go!",
        "4": "Thanks for registering!"
      }[this.step];
    },
    headerSubtitle() {
      return {
        "1": "Takes less than 30 seconds to join.",
        "2": "Takes less than 30 seconds to join.",
        "3": "Demographics to place you in the right study.",
        "4": ""
      }[this.step];
    },
    btnTxt() {
      return {
        "1": "Start Now",
        "2": "Next",
        "3": "Join!"
      }[this.step];
    },
    panelAddress() {
      return {
        "1": "austin@mediasciencelabs.com",
        "2": "chicago@mediasciencelabs.com",
        "4": "newyork@mediasciencelabs.com",
        "5": "austin@mediasciencelabs.com"
      }[this.participant.id_address];
    },
    ...mapGetters([
      "educations",
      "ethnicities",
      "incomes",
      "occupations",
      "error"
    ])
  },
  methods: {
    ...mapActions([
      "createParticipant",
      "fetchEducations",
      "fetchEthnicities",
      "fetchIncomes",
      "fetchOccupations",
      "validateCode"
    ]),
    handleScroll,
    init() {
      this.fetchEducations();
      this.fetchEthnicities();
      this.fetchIncomes();
      this.fetchOccupations();
    },
    checkStep(step) {
      return step === 3 ? this.submit() : this.validateBeforeSubmit();
    },
    track(step, data) {
      if (step === 2) FbEvents.initiateCheckout();
      if (step === 3) FbEvents.secondCheckout();
      if (step === 4) FbEvents.demographicCheckout();
      if (step === 5) FbEvents.completeRegistration(this.participant.email);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.nextStep();
        }
        return;
      });
    },
    isDemValid() {
      // console.log('hello from isDemValid', this.participant);
      return (
        this.participant.id_ethnicity &&
        this.participant.id_education &&
        this.participant.id_occupation &&
        this.participant.id_income
      );
    },
    nextStep() {
      let progress = document.getElementById("progressbar");
      this.step++;
      progress.style.width = 33 * this.step + "%";
    },
    submit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.step++;
          this.isLoading = true;
          this.atResult = false;
          this.addParticipant();
        }
        return;
      });
    },
    dateFormatter(date) {
      // console.log('date in dateFormatter:',date)
      this.participant.dob = date.toISOString();
      this.hiddenDOB = date;
      this.participantDOB(date);
      return date.toDateString();
    },
    disabledDates() {
      let today = new Date(),
        yyyy = today.getFullYear(),
        dd18 = today.getDate(),
        mm18 = today.getMonth(),
        under18Year = yyyy - 18;

      let newDate = new Date(under18Year, mm18, dd18);
      return newDate;
    },
    participantDOB(date) {
      let filtered = filterDOB(date),
        splitArr = splitDOB(filtered[0]),
        mm = splitArr[0].trim(),
        dd = splitArr[1],
        yyyy = splitArr[2];
      if (mm.length === 1) mm = "0" + mm;
      if (dd.length === 1) dd = "0" + dd;
      this.participant.dob_month = mm;
      this.participant.dob_day = dd;
      if (yyyy.length > 4) yyyy = yyyy.substring(0, 5);
      this.participant.dob_year = yyyy;
    },
    addParticipant() {
      //refactor me, plz
      this.participant.metadata = {};
      isNil(this.participant.id_address)
        ? (this.participant.id_address = 1)
        : (this.participant.id_address = Number(this.participant.id_address));
      if (this.participant.gender === "O") {
        this.participant.gender = "F";
        this.participant.metadata["participant_gender"] = "Other";
      }
      if (this.participant.notes === "Friend")
        this.participant.metadata["referred_by"] = this.referred_by;

      this.participant.metadata[
        "How you heard about us?"
      ] = this.participant.notes;
      if (this.participant.notes === "CTA")
        this.participant.metadata["cta"] =
          this.transitLine + " " + this.transitAd;

      if (this.participant.zip_code.length > 5) {
        let zip = this.participant.zip_code;
        this.participant.notes = this.participant.notes + " Zip: " + zip;
        this.participant.zip_code = zip.substring(0, 5);
      }

      // console.log('this.participant:', this.participant);
      this.createParticipant(this.participant).then(
        response => {
          this.loadingAndTracking("success");
        },
        error => {
          if (error.data === "Panel member already exists.") {
            this.loadingAndTracking("error");
            this.error.data =
              "An account with this email address already exists.";
          } else {
            this.loadingAndTracking("error");
            error.data =
              "Hmm...There seems to be an error. Please contact info@themediapanel.com.";
          }
        }
      );
    },
    loadingAndTracking(status) {
      this.isLoading = !this.isLoading;
      this.track(5);
      if (status === "error") this.participantError = !this.participantError;
      if (status === "success")
        this.participantSuccess = !this.participantSuccess;
      this.trackFirebase(status);
    },
    trackFirebase(status) {
      if (!this.participant.metadata["How you heard about us?"])
        this.participant.metadata["How you heard about us?"] = "did not answer";
      let track = { status: status, email: this.participant.email };
      let today = this.getDateTime();
      this.signupsRef
        .child(today)
        .child(uuidV4())
        .set(track);
      this.particpantsRef
        .child(today)
        .child(uuidV4())
        .set(this.participant);
    },
    getDateTime() {
      let timeInMs = Date.now(),
        d = new Date(),
        DD = d.getDate(),
        MM = d.getMonth(),
        YYYY = d.getFullYear(),
        DDMMYYYY = MM + 1 + "-" + DD + "-" + YYYY;
      return DDMMYYYY;
    },
    otherFn() {
      if (this.participant.notes != "CTA") {
        this.ctaClicked = false;
        this.adClicked = false;
      }
      if (this.participant.notes === "CTA") {
        // console.log('this.participant.notes:', this.participant.notes);
        this.ctaClicked = !this.ctaClicked;
        this.adClicked = !this.adClicked;
        this.ctaValue();
      }
    },
    otherValue() {
      var select = document.getElementById("signupcodes"),
        txtVal = document.getElementById("val").value,
        newOption = document.createElement("OPTION"),
        newOptionVal = document.createTextNode(txtVal);

      newOption.appendChild(newOptionVal);
      select.insertBefore(newOption, select.firstChild);
      this.participant.notes = select.firstChild.value;
    },
    friendValue() {
      this.participant.metadata = {};
      var select = document.getElementById("signupcodes"),
        txtVal = document.getElementById("friendVal").value,
        newFriend = document.createElement("OPTION"),
        newFriendVal = document.createTextNode(txtVal);

      newFriend.appendChild(newFriendVal);
      select.insertBefore(newFriend, select.firstChild);
      this.referred_by = select.firstChild.value;
      this.participant.referred_by = this.referred_by;
    },
    adValue() {
      // console.log('hello from adValue');
      // console.log('this.participant.notes:', this.participant.notes);
    },
    ctaValue() {
      this.participant.metadata = {};
      // console.log('hello from ctaValue');
      // console.log('this.participant.notes:', this.participant.notes);
      let txtVal = document.getElementById("ctaVal").value,
        adVal = document.getElementById("adVal").value;
      // console.log('txtVal:', txtVal);
      // console.log('adVal:', adVal);
      if (this.participant.notes === "CTA") {
        // console.log('hello from ');
        this.participant.metadata["cta"] = txtVal + " " + adVal;
      }
    },
    getCTA() {
      let txtVal = document.getElementById("ctaVal").value,
        adVal = document.getElementById("adVal").value;
      // console.log('txtVal:', txtVal);
      // console.log('adVal:', adVal);
      return txtVal + " " + adVal;
    },
    signupCodeValue() {
      let suCodeVal = "";
      this.participant.metadata = {};
      debugger;
      if (document.getElementById("signup_code").value) {
        suCodeVal = document.getElementById("signup_code").value;
      }
      this.participant.signup_code = suCodeVal;
    },
    checkSignUpCode() {
      let sc = document.getElementById("signup_code").value;
      this.validateCode(sc).then(isInvalid => {
        this.invalidSignupCode = isInvalid;
        if (isInvalid) {
          document.getElementById("signup_code").value = "";
        } else {
          this.signupCodeValue();
        }
      });
    }
  }
};
</script>
