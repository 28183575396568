<template>
  <div class="contact__content">
    <div class="proof__body--card" v-for="item in city" :key="item.id">
      <div class="card__img">
        <h2>{{ item.name }}</h2>
      </div>
      <div class="card__body">
        <div class="card__body--content">
          <div class="content__icon">
            <i class="fa fa-lg" :class="icons.faClock"></i>
          </div>
          <div class="content__text">
            <p>{{ item.hours }}</p>
          </div>
        </div>
        <div v-show="item.phone" class="card__body--content">
          <div class="content__icon">
            <i class="fa fa-lg" :class="icons.faPhone"></i>
          </div>
          <div class="content__text">
            <p>{{ item.phone }}</p>
          </div>
        </div>
        <div class="card__body--content">
          <div class="content__icon">
            <i class="fa fa-lg" :class="icons.faMail"></i>
          </div>
          <div class="content__text">
            <p>{{ item.email }}</p>
          </div>
        </div>
        <div class="card__body--content location">
          <div class="content__icon">
            <i class="fa fa-lg" :class="icons.faLocation"></i>
          </div>
          <div class="content__text">
            <p>{{ item.location }}</p>
          </div>
        </div>
        <div class="card__body--content social">
          <!-- <div class="content__icon"><i class="fa fa-lg" :class="icons.faSocial"></i></div> -->
          <div class="content__text social">
            <p>Connect with us.</p>
            <a v-show="item.fb" :href="item.fb" target="_blank"
              ><i class="fa fa-lg fa-facebook-official"></i
            ></a>
            <a v-show="item.twt" :href="item.twt" target="_blank"
              ><i class="fa fa-lg fa-twitter-square"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactContent",
  data() {
    return {
      city: [
        {
          name: "Austin",
          hours:
            "For your convenience, our facilities are open from 11:00am until 7:00pm Monday - Thursday, and 9:00am - 6:00pm Friday - Sunday.",
          phone: "(512) 837-5646",
          email: "austin@mediasciencelabs.com",
          location: "11904 North IH 35 Austin, TX 78753",
          fb: "https://www.facebook.com/mediascienceaustin/",
          twt: "https://twitter.com/mediascienceatx"
        },
        {
          name: "Chicago",
          hours:
            "For your convenience, our facilities are open from 11:00am until 7:00pm Monday - Thursday, and 9:00am - 6:00pm Friday - Sunday.",
          phone: "(312) 372-6300",
          email: "chicago@mediasciencelabs.com",
          location: "8 W Monroe St, 3rd Floor, Chicago, IL 60603",
          fb: "https://www.facebook.com/mediasciencechicago/",
          twt: "https://twitter.com/MediaScienceCHI"
        },
        {
          name: "New York",
          hours:
            "For your convenience, our facilities are open from 9:00am until 5:00pm Monday - Friday.",
          phone: "(646) 476-2183",
          email: "newyork@mediasciencelabs.com",
          location: "552 7th Avenue, Suite 302, New York, NY 10018",
          fb: "https://business.facebook.com/MediaScienceNYC/",
          twt: "https://twitter.com/MediaScienceNYC"
        }
      ],
      icons: {
        faClock: "fa-clock-o",
        faPhone: "fa-phone",
        faMail: "fa-envelope",
        faLocation: "fa-map-marker",
        faSocial: "fa-connectdevelop"
      }
    };
  },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".proof__body--card",
        {
          origin: "bottom",
          distance: "200px",
          opacity: 0,
          delay: 100,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          mobile: false,
          reset: true
        },
        150
      );
    }
  }
};
</script>

<style></style>
