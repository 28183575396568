<template>
  <div class="faq body__content border3">
    <div class="faq__title border">
      <h2>Quick Facts</h2>
    </div>
    <div class="faq__body border">
      <div
        class="faq__body--content border2"
        v-for="fact in facts"
        :key="fact.id"
      >
        <div class="faq__body--icon">
          <check-mark></check-mark>
        </div>
        <div class="faq__body--text">
          <p>{{ fact.msg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckMark from "../../components/CheckMark.vue";
export default {
  name: "LandingFAQ",
  components: { CheckMark },
  data() {
    return {
      facts: [
        {
          icon: "fa fa-sm fa-check-square-o",
          msg: "3 Locations - Austin, Chicago, and New York"
        },
        {
          icon: "fa fa-sm fa-check-square-o",
          msg: "Studies typically last 1-2 hours"
        },
        {
          icon: "fa fa-sm fa-check-square-o",
          msg: "Pay varies based on study length, but starts at $30/hr"
        },
        {
          icon: "fa fa-sm fa-check-square-o",
          msg:
            "More than 500 virtual reality, mobile, television, and live (debates, sports) studies have been conducted "
        },
        // {
        //   icon: "fa fa-sm fa-check-square-o",
        //   msg: "More than 35,000 panel members have completed in-person studies"
        // },
        {
          icon: "fa fa-sm fa-check-square-o",
          msg:
            "Our Chicago lab was featured on Fox’s television program, APB, in 2016",
          link: "http://openhousechicago.org/sites/site/mediascience/"
        }
      ]
    };
  },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".faq__body--icon",
        {
          origin: "top",
          distance: "0px",
          opacity: 0,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          scale: 0.2,
          delay: 500,
          rotate: { x: 200, y: 100, z: 100 }
        },
        600
      );

      sr.reveal(
        ".faq__body--text",
        {
          origin: "bottom",
          distance: "20px",
          opacity: 0,
          // rotate: { x: 0, y: 100, z: 10 },
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          delay: 500
        },
        600
      );

      sr.reveal(
        ".faq__title",
        {
          origin: "bottom",
          distance: "20px",
          opacity: 0,
          delay: 100,
          scale: 0.6,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000
        },
        500
      );
    }
  }
};
</script>

<style></style>
