<template>
  <div class="studyLinks__content">
    <form id="search">
      <input
        name="query"
        v-model="searchQuery"
        placeholder="Search by Name or Study"
      />
    </form>
    <grid-temp
      :data="gridData"
      :columns="gridColumns"
      :filter-key="searchQuery"
    >
    </grid-temp>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GridTemp from "../../components/Grid.vue";
export default {
  name: "StudyLinksContent",
  components: { GridTemp },
  data() {
    return {
      searchQuery: "",
      gridColumns: ["study", "name", "link"],
      gridData: []
    };
  },
  computed: {
    ...mapGetters(["survey"])
  },
  mounted() {
    this.addListeners();
  },
  methods: {
    ...mapActions(["surveyLinks"]),
    addListeners() {
      this.surveyLinks();
      setTimeout(() => {
        this.gridData = this.survey;
      }, 2000);
    }
  }
};
</script>

<style></style>
