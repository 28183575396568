<template>
  <div>
    <svg
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <circle class="circle" cx="16" cy="16" r="16" fill="#33CCFF" />
      <path
        class="check"
        d="M9 16l5 5 9-9"
        fill="none"
        stroke="#fff"
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CheckMark"
};
</script>

<style></style>
