<template>
  <!-- <div class="testimonial body__content--section border3"> -->
  <div class="testimonial body__content border3">
    <!-- <video width="640" height="360" :src="path" type="video/mp4" autoplay controls/> -->
    <div class="testimonial__title">
      <h2>What our panel members have to say</h2>
    </div>
    <div class="testimonial__body">
      <!-- <div class="testimonial__body--review" v-for="testimonial in testimonials" :key="testimonial.id">
        <div class="review__placeholder">
          <a :href="testimonial.link" target="_blank">
            <img :src="testimonial.img" :alt="testimonial.name">
          </a>
        </div>
      </div> -->

      <!-- <div class="testimonial__body--review">
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fwilliam.mitchell.5817%2Fposts%2F10212746770927597%3A0&width=500"
          width="500"
          height="316"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div> -->
      <div class="testimonial__body--review">
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D718416528496584%26id%3D100009847300906&width=500"
          width="500"
          height="160"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      <div class="testimonial__body--review">
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FBriannaClark6%2Fposts%2F10156591057316877&width=500"
          width="500"
          height="160"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      <!-- <div class="testimonial__body--review">
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fakowall2%2Fposts%2F1788550947924331%3A0&width=500"
          width="500"
          height="350"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div> -->
      <!-- <div class="testimonial__body--review">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fgracie.lopez.12%2Fposts%2F2289091741129264%3A0&width=500" width="500" height="370" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
      </div> -->
    </div>

    <!-- <video width="640" height="360" :src="path" type="video/mp4" autoplay controls>
      <source :src="path" type="video/mp4">
    </video> -->
  </div>
</template>

<script>
export default {
  name: "LandingTestimonial",
  data() {
    return {
      // path: '../../../assets/videos/MediaScience.mp4',
      path: "./MediaScience.mp4",
      testimonials: [
        {
          name: "facebook-testimonial",
          img: "./dist/testimony-1.png",
          link:
            "https://www.facebook.com/ivana.bronstrup/posts/1575656725817287:0",
          review:
            "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fivana.bronstrup%2Fposts%2F1575656725817287%3A0&width=500"
        },
        {
          name: "facebook-testimonial",
          img: "./dist/testimony-2.png",
          link:
            "https://www.facebook.com/raichaun.brown/posts/1884464831586645:0",
          review:
            "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fraichaun.brown%2Fposts%2F1884464831586645%3A0&width=500"
        },
        {
          name: "facebook-testimonial",
          img: "./dist/testimony-3.png",
          link:
            "https://www.facebook.com/terrence.burton.71/posts/10212176739727469:0",
          review:
            "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fterrence.burton.71%2Fposts%2F10212176739727469%3A0&width=500"
        },
        {
          name: "facebook-testimonial",
          img: "./dist/testimony-4.png",
          link:
            "https://www.facebook.com/permalink.php?story_fbid=1799272117035529&id=100008582746483&substory_index=0",
          review:
            "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D1799272117035529%26id%3D100008582746483%26substory_index%3D0&width=500"
        }
      ]
    };
  },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".testimonial__body--review",
        {
          origin: "top",
          distance: "80px",
          opacity: 0,
          scale: 0.2,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          delay: 500
        },
        500
      );
    }
  }
};
</script>

<style></style>
