<template>
  <div class="youth-landing">
    <br /><br /><br /><br /><br /><br /><br /><br />
    <div class="hero border2">
      <div class="hero__content maxW_1024">
        <!-- <div class="hero-logo">
          <Logo-White/>
        </div> -->

        <div class="hero-details">
          <div class="hero-text">
            <h1 class="hero-text--welcome hero-reveal">
              Welcome to the MediaScience Youth Panel!
            </h1>
            <p class="hero-text--info1 hero-reveal" style="color: #FFB94A">
              <span>This site is operated by MediaScience </span>
              <span>
                and is used for current MediaScience Panel members to register
                their children or teen aged 17 years and under to participate in
                upcoming studies.</span
              >
            </p>
            <p class="hero-text--info2 hero-reveal">
              <span> Not yet a MediaScience Panel member?</span>
            </p>
            <p class="hero-text--info3 hero-reveal">
              Please visit
              <a href="https://www.mediasciencepanel.com" target="_blank"
                >mediasciencepanel.com</a
              >
              for more information on becoming a member*.
            </p>
            <p class="hero-text--info4 hero-reveal">
              *Must be 18 years of age or older.
            </p>
          </div>
          <hero-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroForm from "./YouthPanelLandingForm.vue";
import LogoWhite from "../../components/LogoWhite.vue";
export default {
  name: "Landing",
  components: { LogoWhite, HeroForm },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".hero-reveal",
        {
          origin: "left",
          distance: "200px",
          opacity: 0,
          scale: 0.8,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000
        },
        80
      );
    }
  }
};
</script>

<style></style>
