<template>
  <div class="hero-form">
    <div class="youth-landing__form">
      <div class="youth-landing__form--header">
        <h2 class="header__title">{{ headerTitle }}</h2>
        <p class="header__subtitle">{{ headerSubtitle }}</p>
      </div>
      <div class="youth-landing__form--body">
        <div class="progress__bar">
          <span id="progressbar" :style="width" :class="progressBg" />
        </div>
        <form
          action=""
          class="signup__form"
          autocomplete="nope"
          @keydown.enter.prevent
        >
          <!-- <transition-group name="router-slide"> -->
          <div
            v-if="step === 1"
            key="1"
            class="signup__form--body signup__form--first"
          >
            <div class="form-group has-feedback">
              <input
                v-validate="'required|email'"
                v-model="guardian.email"
                :class="{ input: true, 'is-danger': errors.has('email') }"
                type="email"
                name="email"
                class="form-control"
                placeholder="Your Email"
                autocomplete="nope"
                required
              />
              <span v-show="errors.has('email')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("email") }}
              </span>
            </div>
            <div class="form-group has-feedback">
              <input
                v-validate="'required|numeric|min:5|max:6'"
                v-model="guardian.member_id"
                :class="{ input: true, 'is-danger': errors.has('id') }"
                type="tel"
                name="id"
                class="form-control"
                placeholder="Your Member ID"
                required
              />
              <span v-show="errors.has('id')" class="validate is-danger">
                <i class=" fa fa-warning" />
                <p>{{ errors.first("id") }}</p>
              </span>
            </div>
            <!-- <div class="form-group has-feedback">
              <input
                v-validate="'required|email'"
                v-model="guardian.email"
                :class="{'input': true, 'is-danger': errors.has('email') }"
                type="email"
                name="email"
                class=""
                placeholder="Your Email"
                autocomplete="nope"
                required>
              <label for="email">Email:</label>
              <span v-show="errors.has('email')"
                    class="validate is-danger">
                <i class=" fa fa-warning"/>
                <p>{{ errors.first('email') }}</p>
              </span>
            </div> -->
            <!-- <div class="form-group has-feedback">
              <input
                v-validate="'required|numeric|min:5|max:5'"
                v-model="guardian.member_id"
                :class="{'input': true, 'is-danger': errors.has('id') }"
                type="tel"
                name="id"
                class=""
                placeholder="Your Member ID"
                required>
              <label for="memberid">Member ID:</label>
              <span v-show="errors.has('id')"
                    class="validate is-danger">
                <i class=" fa fa-warning"/>
                <p>{{ errors.first('id') }} </p>
              </span>
            </div> -->
          </div>
          <!-- </transition-group> -->
          <!-- <transition-group name="router-slide"> -->
          <div
            v-if="step === 2"
            key="2"
            class="signup__form--body signup__form--second"
          >
            <loading-spinner v-if="spinner" />
            <div v-if="spinner" class="verifyMsg">
              <p>Verifying...</p>
            </div>
            <div v-if="signupSuccess" class="verifyMsg">
              <p>MediaScience Panel Member verification confirmed!</p>
              <p>
                You may now proceed in signing up your child or teen for the
                youth panel.
              </p>
              <p>Click next.</p>
            </div>
            <div v-if="signupError" class="verifyMsg verifyError">
              <p>It looks like you are not a MediaScience Panel member.</p>
              <button type="button" class="ms__button">
                <a href="https://www.mediasciencepanel.com" target="_blank">
                  <p class="ms__button-tag">Sign Up Here!</p>
                </a>
              </button>
              <p>
                Return to this page once you've registered your account and
                received a verification email.
              </p>
            </div>
          </div>
          <!-- </transition-group> -->
          <div
            v-if="step === 3"
            key="3"
            class="signup__form--body signup__form--third"
          >
            <div class="form-group has-feedback">
              <input
                v-validate="'required|alpha_dash|min:2'"
                v-model="youthParticipant.first_name"
                :class="{ input: true, 'is-danger': errors.has('firstname') }"
                type="text"
                name="firstname"
                class="form-control"
                placeholder="My Child's First Name"
                autocomplete="nope"
                required
              />
              <!-- <label for="memberid">First Name:</label> -->
              <span v-show="errors.has('firstname')" class="validate is-danger">
                <i class=" fa fa-warning" />
                <p>{{ errors.first("firstname") }}</p>
              </span>
            </div>
            <div class="form-group has-feedback">
              <input
                v-validate="'required|alpha_dash|min:2'"
                v-model="youthParticipant.last_name"
                :class="{ input: true, 'is-danger': errors.has('lastname') }"
                type="text"
                name="lastname"
                class="form-control"
                placeholder="My Child's Last Name"
                required
              />
              <!-- <label for="memberid">Last Name:</label> -->
              <span v-show="errors.has('lastname')" class="validate is-danger">
                <i class=" fa fa-warning" />
                <p>{{ errors.first("lastname") }}</p>
              </span>
            </div>
            <div class="gender-radio">
              <div class="label-error">
                <label class="gender radio-label">My child is</label>
                <span v-show="errors.has('gender')" class="validate is-danger">
                  <i class=" fa fa-warning" />
                  <p>{{ errors.first("gender") }}</p>
                </span>
              </div>
              <div class="button_radio">
                <input
                  v-validate="'required|included:M,F,O'"
                  id="genderbr"
                  v-model="youthParticipant.gender"
                  type="radio"
                  name="gender"
                  value="M"
                />
                <label for="genderbr">
                  <p>Male</p>
                </label>
                <input
                  v-validate="'required|included:M,F,O'"
                  id="genderbr1"
                  v-model="youthParticipant.gender"
                  type="radio"
                  name="gender"
                  value="F"
                />
                <label for="genderbr1">
                  <p>Female</p>
                </label>
                <!-- <input v-validate="'required|included:M,F,O'"
                       id="genderbr2"
                       v-model="youthParticipant.gender"
                       type="radio"
                       name="gender"
                       value="O">
                <label for="genderbr2">
                  <p>Other</p>
                </label> -->
              </div>
            </div>
            <div class="dob-radio">
              <div class="label-error">
                <label class="radio-label">My Child's Birthday</label>
                <span
                  v-show="errors.has('Birthday')"
                  class="validate is-danger"
                >
                  <i class=" fa fa-warning" />
                  <p>{{ errors.first("Birthday") }}</p>
                </span>
              </div>
              <input
                v-validate="'required'"
                v-model="hiddenDOB"
                type="hidden"
                name="Birthday"
                autocomplete="off"
              />
              <datepicker
                :format="dateFormatter"
                :initialView="'year'"
                :disabledDates="state.disabledDates"
                name="Birthday"
                class="datepicker form-control"
              />
            </div>
          </div>
          <div
            v-if="step === 4"
            key="4"
            class="signup__form--body signup__form--fourth"
          >
            <div class="form-group has-feedback">
              <label for="referral">Education</label>
              <select
                id="education"
                name="education"
                v-model="youthParticipant.id_education"
                class="form-control"
                required
              >
                <option
                  v-for="education in youth_educations"
                  :key="education.id"
                  :value="education.id"
                  >{{ education.name }}</option
                >
              </select>
            </div>
            <div class="form-group has-feedback">
              <label for="referral">Ethnicity</label>
              <select
                id="ethincity"
                name="ethincity"
                v-model="youthParticipant.id_ethnicity"
                class="form-control"
                required
              >
                <option
                  v-for="ethnicity in ethnicities"
                  :key="ethnicity.id"
                  :value="ethnicity.id"
                  >{{ ethnicity.name }}</option
                >
              </select>
            </div>
            <div class="form-group has-feedback location-radio">
              <label class="panel_location location-radio-label"
                >Location</label
              >
              <div class="button_radio">
                <select
                  name="location"
                  v-validate="'required|included:1,2,4,5,6'"
                  v-model="youthParticipant.id_address"
                  class="form-control"
                  required
                >
                  <option value="1">Austin</option>
                  <option value="2">Chicago</option>
                  <option value="4">New York</option>
                  <option value="5">Online Only</option>
                  <option value="6">Canada</option>
                </select>
              </div>
              <span v-show="errors.has('location')" class="validate is-danger">
                <i class=" fa fa-warning"></i>
                {{ errors.first("location") }}
              </span>
            </div>
          </div>
          <div
            v-if="step === 5"
            key="5"
            class="signup__form--body signup__form--fifth"
          >
            <loading-spinner v-if="spinner" />
            <div v-if="spinner" class="verifyMsg">
              <p>Verifying...</p>
            </div>
            <div v-if="signupSuccess" class="verifyMsg">
              <p>Success!</p>
              <p>
                You will receive a verification email to your email address on
                file.
              </p>
            </div>
            <div v-if="signupError" class="verifyMsg verifyError">
              <p>It looks like there has been an error.</p>
              <p>Please try again.</p>
            </div>
          </div>
          <div v-show="showButtons" class="signup__form--button">
            <button
              type="button"
              class="ms__button"
              @click.prevent="validateBeforeSubmit(step)"
            >
              <p class="ms__button-tag">{{ btnTxt }}</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import LoadingSpinner from "./YouthPanelSpinner.vue";
import uuidV4 from "uuid/v4";
import { filterDOB, splitDOB } from "../../../utils/helpers.js";
export default {
  name: "LandingForm",
  components: { Datepicker, LoadingSpinner },
  data() {
    return {
      step: 1,
      hiddenDOB: "",
      showButtons: true,
      signupSuccess: false,
      signupError: false,
      spinner: true,
      youthRef: firebase.database().ref("youth"),
      state: {
        disabledDates: {
          to: this.disabledDates(),
          from: new Date()
        }
      }
    };
  },
  mounted() {
    this.fetchEthnicities();
    this.fetchYouthEducations();
  },
  computed: {
    width() {
      return {
        width: 20 * this.step + "%",
        transition: "1s"
      };
    },
    progressBg() {
      return this.step === 5 ? "progress-success" : "progress-warning";
    },
    headerTitle() {
      return {
        "1": "Start Now!",
        "2": "Verifying",
        "3": "Youth Sign Up",
        "4": "Youth Demographics",
        "5": "Thanks for registering!"
      }[this.step];
    },
    headerSubtitle() {
      return {
        "1":
          "If you are a MediaScience Panel member, please sign in below to verify your status before registering your child or teen.",
        "2": "A quick member verification!",
        "3": "Please fill out the information below.",
        "4": "Please fill out the information below."
      }[this.step];
    },
    btnTxt() {
      return {
        "1": "Verify",
        "2": "Next",
        "3": "Next",
        "4": "Join!"
      }[this.step];
    }
  },
  methods: {
    checkStep(step) {
      return step === 4 ? this.submitYouth() : this.validateBeforeSubmit();
    },
    validateBeforeSubmit(step) {
      this.$validator.validateAll().then(result => {
        if (result) {
          return step === 4 ? this.submitYouth() : this.nextStep();
        }
        return;
      });
    },
    nextStep() {
      this.step === 1 ? this.checkGuardian() : this.step++;
    },
    checkGuardian() {
      this.showButtons = !this.showButtons;
      this.step++;
      this.verifyGuardian(this.guardian)
        .then(response => {
          setTimeout(() => {
            if (response === "OK") {
              this.spinner = !this.spinner;
              this.showButtons = !this.showButtons;
              this.signupSuccess = !this.signupSuccess;
            }
          }, 1500);
        })
        .catch(error => {
          let status = error.data.status;
          let response = error.data.data;
          setTimeout(() => {
            if (status === "ERR") {
              this.spinner = !this.spinner;
              this.signupError = !this.signupError;
            }
          }, 1500);
        });
    },
    submitYouth() {
      this.signupSuccess = !this.signupSuccess;
      this.spinner = !this.spinner;
      this.step++;
      this.showButtons = !this.showButtons;
      this.createYouthParticipant(this.youthParticipant)
        .then(response => {
          setTimeout(() => {
            if (response === "OK") {
              this.spinner = !this.spinner;
              this.signupSuccess = !this.signupSuccess;
              this.sendToFirebase(this.youthParticipant);
            }
          }, 1500);
        })
        .catch(error => {
          let status = error.data.status;
          let response = error.data.data;
          setTimeout(() => {
            if (status === "ERR") {
              this.spinner = !this.spinner;
              this.signupError = !this.signupError;
            }
          }, 1500);
        });
    },
    sendToFirebase(data) {
      let youthInfo = { youth: data, guardian: this.guardian };
      let today = this.getDateTime();
      this.youthRef
        .child(today)
        .child(uuidV4())
        .set(youthInfo);
    },
    getDateTime() {
      let timeInMs = Date.now(),
        d = new Date(),
        DD = d.getDate(),
        MM = d.getMonth(),
        YYYY = d.getFullYear(),
        DDMMYYYY = MM + 1 + "-" + DD + "-" + YYYY;
      return DDMMYYYY;
    },
    dateFormatter(date) {
      this.hiddenDOB = date;
      this.participantDOB(date);
      return date.toDateString();
    },
    disabledDates() {
      let today = new Date(),
        yyyy = today.getFullYear(),
        dd18 = today.getDate(),
        mm18 = today.getMonth(),
        under18Year = yyyy - 18;

      let newDate = new Date(under18Year, mm18, dd18);
      // console.log("newDate:", newDate);
      return newDate;
    },
    participantDOB(date) {
      let filtered = filterDOB(date),
        splitArr = splitDOB(filtered[0]),
        mm = splitArr[0],
        dd = splitArr[1],
        yyyy = splitArr[2];
      if (mm.length === 1) mm = "0" + mm;
      if (dd.length === 1) dd = "0" + dd;
      this.youthParticipant.dob_month = mm;
      this.youthParticipant.dob_day = dd;
      if (yyyy.length > 4) yyyy = yyyy.substring(0, 5);
      this.youthParticipant.dob_year = yyyy;
    }
  }
};
</script>

<style></style>
