export const handleScroll = (evt, el) => {
  console.log("window.scrollY:", window.scrollY);

  if (window.scrollY > 100) {
    el.setAttribute(
      "style",
      "background: rgba(252, 253, 255, 0.7); transition: all 500ms cubic-bezier(.4, .25, .3, 1);"
    );
  }
  return window.scrollY > 105;
};
