import * as fbEvents from "./fb_events.js";

export default fbEvents;

export function viewContent(name) {
  window.fbq("track", "ViewContent", { content_name: name });
}
export function initiateCheckout(data) {
  window.fbq("track", "InitiateCheckout", {
    content_name: "panel member entered first,last,email,phone"
  });
}
export function secondCheckout(data) {
  window.fbq("trackCustom", "SecondCheckout", {
    content_name:
      "panel member entered gender,dob,location,how they heard about us"
  });
}
export function demographicCheckout(data) {
  window.fbq("trackCustom", "DemographicCheckout", {
    content_name: "panel member entered dems"
  });
}
export function completeRegistration(data) {
  window.fbq("track", "CompleteRegistration", {
    status: "success",
    content_name: data
  });
}
