import Vue from "vue";
import Vuex from "vuex";
// import * as actions from './actions.js';
// import * as getters from './getters.js';
import { state, mutations } from "./mutations.js";

import demographics from "./modules/demographics";
import participant from "./modules/participant";
import youthpanel from "./modules/youthpanel";
Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    demographics,
    youthpanel,
    participant
  }
});
