<template>
  <div class="footer border2" style="overflow: hidden">
    <div class="footer__content maxW_1024 border">
      <div class="footer__title">
        <h2>MediaScience</h2>
      </div>
      <div class="main">
        <!-- <div class="footer__nav"> -->
        <div
          class="footer__nav footer__navLinks"
          v-for="items in linkItems"
          :key="items.id"
        >
          <router-link class="" :to="items.link"
            ><span class="">{{ items.name }}</span></router-link
          >
        </div>
        <!-- </div> -->
        <div class="footer__social">
          <div
            class="footer__socialLinks"
            v-for="items in socialItems"
            :key="items.id"
          >
            <a class="" :href="items.link" target="_blank"
              ><span class=""><i class="fa fa-lg" :class="items.fa"></i></span
            ></a>
          </div>
        </div>
      </div>
      <div class="footer__subNav">
        <div
          class="footer__subNavLinks"
          v-for="items in subLinkItems"
          :key="items.id"
        >
          <a class="" :href="items.link" target="_blank"
            ><span class="">{{ items.name }}</span></a
          >
        </div>
      </div>
      <div class="footer__copyright">
        <p class="small">
          Copyright &copy; MediaScience Labs 2024. All Rights Reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingFooter",
  data() {
    return {
      linkItems: [
        {
          name: "Home",
          link: "/"
        },
        {
          name: "About",
          link: "/about"
        },
        {
          name: "Studies",
          link: "/studies"
        },
        {
          name: "Location",
          link: "/location"
        },
        {
          name: "Contact",
          link: "/contact"
        },
        {
          name: "Youth Panel",
          link: "/youth-panel"
        }
      ],
      subLinkItems: [
        {
          name: "Privacy Policy",
          link: "/privacy"
        },
        {
          name: "MediaScienceLabs",
          link: "https://www.mediasciencelabs.com"
        }
      ],
      socialItems: [
        {
          name: "Facebook",
          fa: "fa-facebook-official",
          link: "https://www.facebook.com/mediascienceaustin/"
        },
        {
          name: "Twitter",
          fa: "fa-twitter-square",
          link: "https://twitter.com/mediascienceatx"
        }
      ]
    };
  }
};
</script>
