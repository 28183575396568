<template>
  <div class="proof body__content--section">
    <div class="proof__title">
      <!-- <div class="proof__body--card"> -->
      <h2>Why Join the MediaScience Panel?</h2>
      <!-- </div> -->
    </div>
    <div class="proof__body">
      <div class="proof__body--card" v-for="proof in proofs" :key="proof.id">
        <div class="card__img">
          <img :src="proof.img" />
        </div>
        <div class="card__body">
          <p>{{ proof.msg.title }}</p>
          <p>{{ proof.msg.body }}</p>
          <p class="body2">{{ proof.msg.body2 }}</p>
        </div>
        <div class="mobile-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingProof",
  data() {
    return {
      proofs: [
        {
          img: "./images/surveys-blue.png",
          // img: '../.../../../assets/images/surveys-blue.png',
          msg: {
            title: "Your Opinion Matters",
            body:
              "Have you ever watched a football game? Used social media? Watched TV? Then you’ve probably seen the results of our research.",
            body2:
              "Some of the biggest players in media make decisions about what’s next based off insights provided by our panel members.",
            article:
              "http://adage.com/article/media/nfl-puts-fans-lab-works-football-seeks-answers/309775/?utm_source=mediaworks&utm_medium=newsletter&utm_campaign=adage&ttl=1500930633&utm_visit=605644"
          }
        },
        {
          img: "./images/biometrics-blue.png",
          // img: '../.../../../src/assets/images/biometrics-blue.png',
          msg: {
            title: "State-of-the-Art",
            body:
              "From traditional surveys to biometric research, our facilities feel like home, but are as advanced as the starship Enterprise.",
            body2: "State-of-the-Art doesn’t even begin to describe it.",
            article:
              "http://www.adweek.com/digital/facebook-is-building-its-own-neuroscience-center-to-study-marketing/ "
          }
        },
        {
          img: "./images/neuropoll-blue.png",
          // img: '../.../../../src/assets/images/neuropoll-blue.png',
          msg: {
            title: "People Like You",
            body:
              "More than 70,000 people across the country give our clients the insight they need to advance media and technology, which can help make media more user friendly.",
            body2:
              "Come discover how you can contribute to the rapid changes in the media industry today.",
            article:
              "http://www.512tech.com/news/tech-science/you-say-you-clinton-supporter-but-your-biometric-data-says-you-aren/S3GrDCNFXIc7bpit7RxgFK/ "
          }
        }
      ]
    };
  },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".proof__body--card",
        {
          origin: "bottom",
          distance: "20px",
          opacity: 0,
          delay: 100,
          scale: 0.6,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000
        },
        500
      );

      sr.reveal(
        ".proof__title",
        {
          origin: "bottom",
          distance: "20px",
          opacity: 0,
          delay: 100,
          scale: 0.6,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000
        },
        500
      );
    }
  }
};
</script>

<style></style>
