import MainView from "../components/pages/MainView.vue";
import Landing from "../components/pages/Landing/Landing.vue";
import SubPage from "../components/pages/SubPages/SubPage.vue";
import Admin from "../components/pages/Admin/Admin.vue";
import YouthPanel from "../components/pages/YouthPanel/YouthPanelLanding.vue";

export const routes = [
  {
    path: "/",
    component: MainView,
    children: [
      { name: "Home", path: "", component: Landing },
      { name: "About", path: "/about", component: SubPage },
      { name: "Studies", path: "/studies", component: SubPage },
      { name: "Location", path: "/location", component: SubPage },
      { name: "Contact", path: "/contact", component: SubPage },
      { path: "/privacy", component: SubPage },
      { path: "/confirm-email", component: SubPage },
      { path: "/survey-links", component: SubPage },
      { path: "/site", component: SubPage },
      { name: "Youth Panel", path: "/youth-panel", component: YouthPanel }
      // {path: '/rewards', component: Rewards},
      // {path: '/unsubscribe/:id', component: Unsubscribe},
      // {path: '/contestrules', component: ContestRules},
    ]
  },
  { path: "/admin", component: Admin }
  // { path: "/youth-panel", component: YouthPanel }
];
