<template>
  <div class="body border2">
    <div class="skewed__square--body"></div>
    <div class="body__content maxW_1024 border">
      <!-- <landing-proof></landing-proof> -->
      <!-- <landing-studies/> -->
      <landing-testimonial v-if="width >= 550" />
      <!-- <landing-faq></landing-faq> -->
      <!-- <landing-signup></landing-signup> -->
    </div>
  </div>
</template>

<script>
import LandingProof from "./LandingProof.vue";
import LandingTestimonial from "./LandingTestimonial.vue";
import LandingFaq from "./LandingFAQ.vue";
import LandingSignup from "./LandingSignup.vue";
export default {
  name: "LandingBody",
  components: { LandingProof, LandingTestimonial, LandingFaq, LandingSignup },
  data() {
    return {
      width: 0
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    }
  }
};
</script>

<style></style>
