<template>
  <div class="signup body__content--section border3">
    <div class="signup__request border">
      <h2>Ready to join?</h2>
      <p>Sign up to get started.</p>
    </div>
    <div class="signup__button">
      <button class="ms__button" @click.prevent="goToForm">
        <p class="ms__button-tag">Sign Up</p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingSignup",
  data() {
    return {};
  },
  methods: {
    goToForm() {
      let path = this.$route.path;
      path = path.replace(/\//g, "");
      console.log("path:", path);
      if (path != "") {
        this.$router.push("/");
      } else {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>

<style></style>
