<template>
  <div class="privacy__content">
    <p>{{ effectiveDate }}</p>
    <div
      class="privacy__content--paragraphs"
      v-for="item in info"
      :key="item.id"
    >
      <h3>{{ item.header }}</h3>
      <p>{{ item.body1 }}</p>
      <p>{{ item.body2 }}</p>
      <p>{{ item.body3 }}</p>
    </div>
    <!-- <div class="privacy__content--contact" v-for="item in city" :key="item.id">
      <h3>{{item.name}}</h3>
      <p>{{item.phone}}</p>
      <p>{{item.fax}}</p>
      <p>{{item.address}}</p>
      <div class="contact__info">
          <div class="contact__info--name"></div>
          <div class="contact__info--text"><p>{{item.hours}}</p></div>
        </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "PrivacyContent",
  data() {
    return {
      effectiveDate: "Effective 06/10/2009",
      info: [
        {
          header: "Commitment to Privacy",
          body1:
            "Your privacy is important to us. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. We will never sell or trade your information to any third parties.",
          body2: ""
        },
        {
          header: "Collected Information",
          body1: "Name",
          body2: "Contact Information",
          body3: "Demographic Information"
        },
        {
          header: "How the Information is Used",
          body1:
            "We will use your contact information to inform you about important MediaScience news (no spam!) and to invite you to participate in research studies.",
          body2:
            "We understand the sensitive nature of the demographic details. The information is important for selecting participants who represent a wide range of the American population. Each media study we conduct has various sample demographic restrictions and the panel members we invite to participate for the study are contacted based on these requirements. Demographic information is also often used in data analysis, however it is only associated with your Member ID. Your name will never be used in the data analysis and reporting phases of our research.",
          body3:
            "Demographic and contact information will not be used for any other purposes other than those described above."
        },
        {
          header: "Our Commitment to Data Security",
          body1:
            "This website takes every precaution to protect our users’ information. When you submit sensitive information via the website, your information is protected both online and off-line. If you have any questions about the security at our web site, please email admin@themediapanel.com",
          body2: ""
        },
        {
          header: "Contacting Us",
          body1:
            "If you have any questions or concerns, you may contact us by phone, fax, email, or letter."
          // body2:'Contact Information'
        },
        {
          header: "Notice of Changes",
          body1:
            "Changes to our Privacy Policy will be posted on this web page."
        }
      ],
      city: [
        {
          name: "Austin",
          phone: "(512) 837 – 5646",
          fax: "(512) 837 – 5649",
          address: "11904 North IH 35 Austin, Texas 78753"
        },
        {
          name: "Chicago",
          phone: "(312) 372-6300",
          fax: "",
          address: "8 W Monroe St, 3rd Floor, Chicago, IL 60603"
        },
        {
          name: "New York City",
          phone: "(646) 998-3601",
          fax: "",
          address: "189 Sullivan St New York, NY 10012"
        }
      ]
    };
  }
};
</script>

<style></style>
