<template>
  <div class="location__content">
    <div class="proof__body--card" v-for="item in locations" :key="item.id">
      <div class="card__img">
        <iframe
          :src="item.link"
          width="100%"
          height="100%"
          frameborder="0"
          style="border:0"
          allowfullscreen
        ></iframe>
      </div>
      <div class="card__body">
        <h2>{{ item.name }}</h2>
        <h4>{{ item.address }}</h4>
        <h4>{{ item.city }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationContent",
  data() {
    return {
      locations: [
        {
          address: "11904 North IH 35",
          city: "Austin, TX 78753",
          name: "Austin",
          link:
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13767.111691399727!2d-97.6738237!3d30.3856634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9923b083ec581cfd!2sMediaScience!5e0!3m2!1sen!2sus!4v1496849001031"
        },
        // {
        //   address: "3443 Ed Bluestein Blvd",
        //   city: "Austin, TX 78721",
        //   name: "Austin East",
        //   link:
        //     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3445.690626532937!2d-97.66956538487942!3d30.274394881799182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9188593ae81f9367!2sMediaScience+East!5e0!3m2!1sen!2sus!4v1535488672754"
        // },
        {
          address: "8 W Monroe St, 3rd Floor",
          city: "Chicago, IL 60603",
          name: "Chicago",
          link:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.5535830986696!2d-87.63034504905443!3d41.88095027911993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2ca4ae1b8571%3A0x8ec034a985d02a75!2sMediaScience!5e0!3m2!1sen!2sus!4v1496849976479"
        },
        {
          address: "552 7th Avenue, Suite 302",
          city: "New York, NY 10018",
          name: "New York",
          link:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.336086892819!2d-73.99058408459373!3d40.75463217932724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259ab5d0dab87%3A0x3bda0ea5621c572a!2s552%207th%20Ave%20%23302%2C%20New%20York%2C%20NY%2010018!5e0!3m2!1sen!2sus!4v1572453660927!5m2!1sen!2sus"
        }
      ]
    };
  },
  mounted() {
    this.reveal();
  },
  methods: {
    reveal() {
      sr.reveal(
        ".proof__body--card",
        {
          origin: "bottom",
          distance: "200px",
          opacity: 0,
          delay: 100,
          easing: "cubic-bezier(.4, .25, .3, 1)",
          duration: 1000,
          mobile: false,
          reset: true
        },
        150
      );
    }
  }
};
</script>

<style></style>
