export const state = {
  apiURL: process.env.VUE_APP_API_URL,
  apiKey: process.env.VUE_APP_API_KEY,
  // participantKey: 'B9796FFFFB3CB7964B3AF776A7A9A',
  // contactKey: 'RIi6NW3DXdnab288or7Y8XGYPmfAEun5',
  demKey: process.env.VUE_APP_DEM_KEY,
  apiChiURL: process.env.VUE_APP_API_CHI_URL,
  // apiURL: 'https://dashboard.themediapanel.com/api/confirm/',
  apiRegURL: process.env.VUE_APP_API_REG_URL,
  apiSurveyURL: process.env.VUE_APP_API_SURVEY_URL,
  apiRegKey: process.env.VUE_APP_API_REG_KEY,
  apiSurveyKey: process.env.VUE_APP_API_SURVEY_KEY,
  guardianURL: process.env.VUE_APP_GUARDIAN_URL,
  apiKeyYouth: process.env.VUE_APP_API_KEY_YOUTH,
  youthURL: process.env.VUE_APP_YOUTH_URL
};
export const mutations = {};
